$black: #0b0606;
$white: #fff;
$gold: #b3a063;
$gray: #eaeaea;
$blue: #2196f3;
$blue-dark: #0a78d0;

$bfth: #ff609f; // BFTH COLOR

$c1: #676124;
$c2: #708bf0;
$c3: #94693e;
$c4: #e14c6e;
$c5: #2d5b17;
$c6: #dcaff0;
$c7: #9d5970;
$c8: #fc7ec7;
$c9: #4b19e6;
$c10: #23f9e0;
$c11: #6f187e;
$c12: #a1d0f0;
$c13: #10e55b;
$c14: #c748d0;
$c15: #535062;
$c16: #e81da1;
$c17: #3d4bc8;
$c18: #d273a7;
$c19: #f3d990;
$c20: #bc4754;
$c21: #69ff18;
$c22: #b2ff18;
$c23: #a7402f;
$c24: #246db0;
$c25: #a7c269;
