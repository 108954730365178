@import '../../../assets/styles/colors';

.input {
  width: 100%;
  height: 30px;
  border: 4px solid $bfth; // BFTH COLOR
  text-align: center;
  font-weight: 700;
  font-size: 19px;
  outline: none;

  &:disabled {
    border-color: rgba($bfth, 0.5);
    background-color: rgba(239, 239, 239, 0.3);
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
