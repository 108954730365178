@import '../assets/styles/variables.scss';

.container {
  position: relative;
  width: 100%;
  padding-right: $gutterWidth;
  padding-left: $gutterWidth;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -$gutterWidth;
  margin-left: -$gutterWidth;
}

.col {
  position: relative;
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  width: 100%;
  max-width: 100%;
  min-height: 1px;
  padding: 0 $gutterWidth;
}

@for $i from 1 to $gridCols {
  .col-#{$i} {
    flex: 0 0 calc(100% * #{$i} / #{$gridCols});
    max-width: calc(100% * #{$i} / #{$gridCols});
  }
}

.justify-content {
  &-start {
    justify-content: flex-start;
  }

  &-end {
    justify-content: flex-end;
  }

  &-center {
    justify-content: center;
  }
}

.align-items {
  &-start {
    align-items: flex-start;
  }

  &-end {
    align-items: flex-end;
  }

  &-center {
    align-items: center;
  }
}

.position {
  &-left {
    flex: 0 0 auto;
    width: auto;
    margin-left: 0;
    margin-right: auto;
  }

  &-right {
    flex: 0 0 auto;
    width: auto;
    margin-left: auto;
    margin-right: 0;
  }

  &-center {
    flex: 0 0 auto;
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
}
