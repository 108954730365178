//Grid
$gutterWidth: 10px;
$gridCols: 12;

//media
$breakpoint-desktop-min: 1081px;
$breakpoint-tablet-landscape-max: 1024px;
$breakpoint-tablet-portrait-max: 768px;
$breakpoint-mobile-portrait-max: 414px;
$breakpoint-mobile-landscape-max: 812px;
$breakpoint-laptop-landscape: 1181px;

//buttons
$spriteElementsByY: 10;
$spriteElementsByX: 5;
$btnActivePosition: 27.53;
$btnHoverPosition: 50;
$btnDisabledPosition: 72.51;
$btnErrorPosition: 95.022;
