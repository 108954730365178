@import '../../../assets/styles/colors';
.slider {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  &-range {
    width: 100%;

    &.disabled {
      opacity: 0.6;
    }
  }

  &-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

    &__item {
      font-size: 10px;
      line-height: 16px;
      color: #000;
    }
  }

  &-wrap {
    position: relative;
    width: 100%;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      margin: auto;
      border-radius: 1px;
      background-color: $bfth; // BFTH COLOR
    }

    &__progress {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      height: 2px;
      margin: auto;
      border-radius: 1px;
      background-color: $bfth; // BFTH COLOR
    }

    &__control {
      position: relative;
      z-index: 2;
      width: 100%;
    }

    input[type='range'] {
      -webkit-appearance: none;
      width: 100%;
      margin: 0;
      background: transparent;
      outline: none;
      -webkit-transform: translateZ(0);
    }
    input[type='range']::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 7px;
      height: 14px;
      border: 0.5px solid #fff;
      border-radius: 50%;
      cursor: pointer;
      transform: translate(0, 0);
      background-color: $bfth; // BFTH COLOR
    }

    /* All the same stuff for Firefox */
    input[type='range']::-moz-range-thumb {
      width: 7px;
      height: 14px;
      border: 0.5px solid #fff;
      border-radius: 50%;
      cursor: pointer;
      transform: translate(0, 0);
      background-color: $bfth; // BFTH COLOR
    }

    /* All the same stuff for IE */
    input[type='range']::-ms-thumb {
      width: 7px;
      height: 14px;
      border: 0.5px solid #fff;
      border-radius: 50%;
      cursor: pointer;
      transform: translate(0, 0);
      background-color: $bfth; // BFTH COLOR
    }
  }
}

.mobileSlider {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  &-range {
    width: 100%;

    &.disabled {
      opacity: 0.6;
    }
  }

  &-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

    &__item {
      font-size: 10px;
      line-height: 16px;
      color: #000;
    }
  }

  &-wrap {
    position: relative;
    width: 100%;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      margin: auto;
      border-radius: 1px;
      background-color: $bfth; // BFTH COLOR
    }

    &__progress {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      height: 2px;
      margin: auto;
      border-radius: 1px;
      background-color: $bfth; // BFTH COLOR
    }

    &__control {
      position: relative;
      z-index: 2;
      width: 100%;
    }

    input[type='range'] {
      -webkit-appearance: none;
      width: 100%;
      margin: 0;
      background: transparent;
      outline: none;
    }

    input[type='range']::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 20px;
      height: 20px;
      background-clip: padding-box;
      border-radius: 50%;
      cursor: pointer;
      border: 5px solid transparent;
      background-color: $bfth; // BFTH COLOR
      transform: scale3d(2, 2, 1) translateZ(0);
    }

    /* All the same stuff for Firefox */
    input[type='range']::-moz-range-thumb {
      width: 20px;
      height: 20px;
      background-clip: padding-box;
      border: 5px solid transparent;
      background-color: $bfth; // BFTH COLOR
      border-radius: 50%;
      cursor: pointer;
      transform: scale3d(4, 4, 1) translateZ(0);
    }

    /* All the same stuff for IE */
    input[type='range']::-ms-thumb {
      width: 20px;
      height: 20px;
      background-clip: padding-box;
      border: 5px solid transparent;
      background-color: $bfth; // BFTH COLOR
      border-radius: 50%;
      cursor: pointer;
      transform: scale3d(4, 4, 1) translateZ(0);
    }
  }
}

.isDefault {
  .slider-wrap {
    &:before {
      background-color: #000;
    }

    &__progress {
      background-color: #cacaca;
    }

    input[type='range']::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 14px;
      height: 14px;
      border: none;
      background-color: #fff;
    }

    /* All the same stuff for Firefox */
    input[type='range']::-moz-range-thumb {
      width: 14px;
      height: 14px;
      border: none;
      background-color: #cacaca;
    }

    /* All the same stuff for IE */
    input[type='range']::-ms-thumb {
      width: 14px;
      height: 14px;
      border: none;
      background-color: #cacaca;
    }
  }
}
