@import '../../../assets/styles/colors';

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:disabled + .slider {
      opacity: 0.4;
    }
  }

  &.checked {
    .slider {
      background-color: $bfth; // BFTH COLOR

      &:before {
        transform: translateX(18px);
      }
    }
  }
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 34px;
  border: 1px solid #959595;
  background-color: #323232;
  transition: 0.4s;
  cursor: pointer;

  .layout_isLeftHand__qhZlv & {
    right: 0;
    left: auto;
  }

  &:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 1px;
    bottom: 1px;
    border: 1px solid #646464;
    background-color: #fff;
    border-radius: 50%;
    transition: 0.4s;
  }
}
