@import '../assets/styles/variables.scss';

@import '../../../assets/styles/colors';

.btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  outline: none;
  pointer-events: all;
  padding: 0;
}

.button_ok,
.button_cancel {
  width: 80px;
  min-width: 80px;
  height: 17px;
  min-height: 17px;
  background: none;
  color: transparent;
  border-radius: 5px;
  transition: box-shadow 0.3s ease;

  &:disabled {
    opacity: 0.6;
    cursor: default;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 80%;
    height: 80%;
    margin: auto;
    background-color: #fff;
  }
}

.button_ok {
  background-color: #188c27;
  box-shadow: 0 0 0 0 #2d580e inset, 1px 1px 0 0 #2d580e;

  &:before {
    clip-path: polygon(39% 33%, 44% 59%, 67% 19%, 43% 81%, 33% 42%);
  }

  &:active {
    box-shadow: 1px 1px 0 0 #2d580e inset, 0 0 0 0 #2d580e;
  }
}

.button_cancel {
  background-color: #8c1416;
  box-shadow: 0 0 0 0 #530e0e inset, 1px 1px 0 0 #530e0e;

  &:before {
    clip-path: polygon(
      44.3% 3%,
      40.6% 21.9%,
      45.8% 48%,
      40.6% 74%,
      44.3% 93%,
      49.6% 66%,
      54.9% 92.6%,
      58.5% 74%,
      53.3% 48%,
      58.5% 22%,
      54.9% 3.5%,
      49.6% 30%
    );
  }

  &:active {
    box-shadow: 1px 1px 0 0 #530e0e inset, 0 0 0 0 #530e0e;
  }
}

.close_btn {
  width: 25px;
  height: 25px;
  border: 2px solid $bfth; // BFTH COLOR
  border-radius: 100%;
  background-color: transparent;
  transition: opacity 0.3s ease;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 14px;
    height: 2px;
    border-radius: 1px;
    background-color: $bfth; // BFTH COLOR
    transform-origin: center;
  }

  &::before {
    transform: translate(-7px, -1px) rotate(45deg);
  }

  &::after {
    transform: translate(-7px, -1px) rotate(-45deg);
  }

  &:hover {
    opacity: 0.5;
  }
}

.betSettingsBtn {
  height: 25px;
  border-radius: 5px;
  font-weight: bold;
  color: #000;
  box-shadow: -1px -1px 0 1px $bfth inset;
  background-color: #fff;
  transition: all 0.3s ease, background-color 0.3s ease, opacity 0.3s ease;

  &:disabled {
    text-shadow: none;
    cursor: default;
    opacity: 0.5;
  }

  &.active:not(:disabled) {
    background-color: $bfth;
    box-shadow: 1px 1px 0 1px $bfth inset;
  }
  &.focus:not(:disabled) {
    background-color: $bfth;
    box-shadow: 1px 1px 0 1px $bfth inset;
  }
}

.autoPlaySettingBtn {
  width: 60px;
  height: 35px;
  margin: 2px;
  border-radius: 3px;
  font-weight: bold;
  color: #000;
  box-shadow: -1px -1px 0 1px $bfth inset;
  background-color: #fff;
  transition: all 0.3s ease, background-color 0.3s ease, opacity 0.3s ease;
  cursor: pointer;
  outline: none;

  &:disabled {
    text-shadow: none;
    cursor: default;
    opacity: 0.5;
  }

  @media screen and (min-width: $breakpoint-laptop-landscape) {
    &:hover:not(:disabled) {
      background-color: $bfth;
      box-shadow: 1px 1px 0 1px #000 inset;
    }
  }

  &.active:not(:disabled) {
    background-color: $bfth;
    box-shadow: 1px 1px 0 1px #000 inset;
  }
}

.menuBtn {
  width: 60px;
  height: 39px;
  background-color: transparent;
  background-size: 100%;

  &.info {
    background-image: url('../assets/images/ui/button-info.png');
  }

  &.settings {
    background-image: url('../assets/images/ui/button-settings.png');
  }

  &.help {
    background-image: url('../assets/images/ui/button-help.png');
  }

  &.history {
    background-image: url('../assets/images/ui/button-history.png');
  }

  &.lobby {
    background-image: url('../assets/images/ui/button-lobby.png');
  }
}
