@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.bonus-list {
  justify-content: center;
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  overflow-y: auto;

  .img__bonusImg {
    display: flex;
    width: 100%;
    height: auto;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #010101;
  }
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $bfth; // BFTH COLOR
  }
}

.album-list {
  width: 90vw;
  height: 70vh;
  max-width: 1100px;
  max-height: 800px;
  overflow: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #010101;
  }
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $bfth; // BFTH COLOR
  }

  &__item {
    display: flex;
    margin-left: 30px;
    width: 75%;
    height: auto;
    justify-content: center;
    &:nth-child(n) {
      margin-top: 20px;
    }

    .img {
      display: flex;

      &__chara {
        margin-right: 25px;
        width: 25%;
        height: auto;
        max-width: 150px;
        max-height: 150px;
      }

      &__imgHeart {
        margin-top: 10px;
        width: 25%;
        height: auto;
        max-width: 150px;
        max-height: 150px;
      }

      &__imgHeart2 {
        margin-top: 10px;
        width: 25%;
        height: auto;
        max-width: 150px;
        max-height: 150px;
        scale: 0.8;
      }

      &__imgHeartBig {
        margin-top: 10px;
        width: 25%;
        height: auto;
        max-width: 150px;
        max-height: 150px;
      }
    }

    .img__imgHeartBig:hover {
      scale: 1.1;
    }
  }
}
